import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';
import styled                  from '@emotion/styled';
import { Container, Row, Col } from 'react-grid-system';

import CallToAction      from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading           from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer            from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper           from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel          from '@interness/web-core/src/components/media/Carousel/Carousel';
import MoreExpander      from '@interness/web-core/src/components/structure/MoreExpander/MoreExpander';
import Display           from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';

const List = styled.ul`
  margin-left: 0;
  li {
    margin: 0;
  }
`;

const InfoBox = styled.div`
  color: ${props => props.theme.brand_color};
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 2px;
  margin: 50px auto 0 auto;
  text-align: center;
  p {
    font-size: 1.6rem;
    margin-bottom: 0;
    font-weight: bold;
  }
`;


function IndexPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.media.map(image => {
          return (
            <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          );
        })}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Franz Knops</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Herzlich willkommen bei Juwelier Franz Knops, Ihrem exklusiven Fachgeschäft für Uhren und Schmuck im malerischen Kleve. Seit Jahren stehen wir für Qualität, Eleganz und handwerkliches Können, wenn es um die Auswahl und Gestaltung von Trauringen, Eheringen, Antragsringen und schönen Schmuckstücken geht.</p>
            <p>In unserem Juweliergeschäft präsentieren wir Ihnen eine sorgfältig ausgewählte Kollektion von Uhren und Schmuck, die sowohl zeitlose Klassiker als auch moderne Designs umfasst. Unsere Leidenschaft für feine Materialien und präzise Handwerkskunst zeigt sich in jedem Stück, das wir anbieten. Ganz gleich, ob Sie auf der Suche nach einem besonderen Antragsring sind oder ein individuelles Schmuckstück wünschen, unser erfahrenes Team steht Ihnen mit fachkundiger Beratung zur Seite.</p>
            <p>Neben unserem vielfältigen Sortiment bieten wir einen umfangreichen Reparatur- und Wartungsservice für Ihre Uhren und Schmuckstücke an. Unsere Experten sorgen dafür, dass Ihre wertvollen Stücke stets in bestem Zustand erhalten bleiben.</p>
            <p>Besuchen Sie uns in Kleve und entdecken Sie bei Juwelier Franz Knops die Welt der feinen Uhren und des exquisiten Schmucks. Wir freuen uns darauf, Sie mit unserer Auswahl zu begeistern und Ihnen ein unvergessliches Einkaufserlebnis zu bieten.</p>
            <p>Lassen Sie sich von unserer Expertise leiten und finden Sie das perfekte Schmuckstück oder die ideale Uhr, die Ihre Persönlichkeit unterstreicht und Ihre besonderen Momente bereichert.</p>
            <p>Bis bald in Kleve, Ihr Team von Juwelier Franz Knops</p>

          </span>
        </section>
        <LiveAnnouncements/>
        <section>
          <Spacer/>
          <InfoBox>
            <p>Selbstverständlich werden bei uns im Haus auch professionell Ohrlöcher gestochen: <br/> ab 25.-€</p>
          </InfoBox>
        </section>
        <section>
          <Spacer/>
          <Display/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'}>Unser Service für Sie</Heading>
          <p>Kompletter Service ist für unser Team selbstverständlich. Darum werden Schmuck und Uhren in unserer eigenen
            Werkstatt repariert, umgearbeitet oder angefertigt. So erfüllen wir Ihre Wünsche schnell, sicher und mit
            persönlichem Ansprechpartner.</p>
          <MoreExpander>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h3>Armbanduhren</h3>
                  <List>
                    <li>mechanische Uhren / Handaufzug</li>
                    <li>Quarzarmbanduhren</li>
                    <li>Automatikuhren</li>
                    <li>Batteriewechsel</li>
                    <li>Komplette Revisionen</li>
                    <li>Taschenuhren</li>
                    <li>Glasersatz</li>
                    <li>Leder-, Metall-, Kunststoffuhrarmbänder</li>
                    <li>Bandanpassung</li>
                  </List>
                </Col>
                <Col md={6}>
                  <h3>Goldschmiedereparaturen</h3>
                  <List>
                    <li>Goldschmiedereparaturen aller Art</li>
                    <li>Kette und Ösen zulöten, Verschlüsse ersetzen</li>
                    <li>Ringgrößen ändern</li>
                    <li>Perlenketten neu fassen oder knoten</li>
                    <li>Trauringumarbeitung</li>
                    <li>Neuanfertigungen</li>
                    <li>Schmuck reinigen und aufarbeiten</li>
                    <li>Gravuren in Ringe, Anhänger, Uhren, usw.</li>
                  </List>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h3>Großuhren</h3>
                  <List>
                    <li>Heim – und Tischuhren, Jahresuhren</li>
                    <li>Standuhren</li>
                    <li>Regulatoren</li>
                  </List>
                </Col>
              </Row>
            </Container>
          </MoreExpander>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "index-header"}) {
      ...MediaCollectionHeaderImages
    }
  }
`;
